<template>
<div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
     

            <main >
                <div class="main_content align-items-center">
                    <div class="row justify-content-between mb_40">
                        <div class="col-md-8">
                            <div class="heading">
                                <h2>Add New User </h2>
                                <h3>PBE Management Dashboard</h3>
                            </div>
                        
                        </div>
                        <div class="col-md-4 text-end">
                            <router-link :to="{ name: 'add_Permission' }" class="btn btn_primary">Add Permission Group</router-link>
                        </div>
                    </div>

                    <div class="card">
                    <div class="row ">
                        <div class="col-md-6">
                                <div class="form-group">
                                <label for="exampleInputEmail1" class="form-label"> First Name</label>
                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Type Here">                             
                                </div>           
                        </div>
                        <div class="col-md-6">
                                <div class="form-group">
                                <label for="exampleInputEmail1" class="form-label"> Last Name</label>
                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Type Here">                             
                                </div>         
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="form-label"> Email</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Type Here">                             
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="form-label"> Password</label>
                                <input type="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Type Here">                             
                            </div>             
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="form-label"> User type</label>
                                <v-select   :options="['PB user','Client User']" />                      
                            </div>
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-md-6">
                            <div class="form_check_box d-flex align-items-center mb_24">
                                <input type="checkbox" value="" aria-label="..." id="flexCheckDefault" class="form-check-input ms-0">
                                <label for="flexCheckDefault" class=""> Send User Notification </label>
                            </div>
                            <div class="form_check_box d-flex align-items-center mb_24">
                                <input type="checkbox" value="" aria-label="..." id="flexCheckDefault1" class="form-check-input ms-0">
                                <label for="flexCheckDefault1" class=""> Account Deactivated </label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt_24">
                        <div class="col-md-4">

                            <a href="#" class="btn btn_primary mr_24">Add</a>
                            <a href="#" class="btn btn_outline">Cancel</a>
                        </div>
                    </div>
                    </div>

                    
                </div>

            </main>
        <!-- </div>
        </div>
  </div> -->
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css"
export default {
    name: "user_group"
}
</script>